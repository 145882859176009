import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '53, 97, 171',
		'primary-dark': '37, 55, 133',
		'accent': '255, 214, 0',
		'accent-plus': '0, 0, 0',
	},
});
